<template>
    <div style="width: 100%">
        <div style="margin-top: 20px;">
            <div class="hand op sc" v-if="topichiding" @click="addatopic" style="display: flex;align-items: center;padding-bottom: 10px;width: 100px">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/add_expert.png" style="width: 20px;height: 20px;">
                <span style="color: #1C7EFF;font-size: 18px;margin-left: 5px;">添加话题</span>
            </div>
        </div>
        <div v-for="(item,index) in experttempinfo.topicList" :key="index">
            <div v-if="item.edit == false" class="info_content"  >
                <div style="display: flex;width: 100%">
                    <div style="width: 85%">
                        <div class="namespace">话题名称：<span style="color: #031F88">{{item.title}}</span></div>
                        <div class="namespace">咨询收费标准：<span style="color: #031F88">{{item.money}}</span> 元</div>
                        <div class="namespace">话题介绍：<span style="color: #031F88">{{item.detail}}</span></div>
                    </div>
                    <div style="display: flex;margin-top: 5px;margin-right: 200px;width: 10%">
                        <div class="hand op sc"  @click="edittopic(item)" style="margin-right: 20px;cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/edit.png" style="width: 34px;height: 34px">
                        </div>
                        <div class="hand op sc" @click="delExpertTopic(item.id)" style="cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/delete.png" style="width: 34px;height: 34px">
                        </div>
                    </div>
                </div>
                <div class="dashedpace" v-if="index !== experttempinfo.topicList.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;"></div>
            </div>
            <div v-if="item.edit == true">
                <adddTopic @cancel-edit="removethetopic(item)" :topiclist_code="item"></adddTopic>
            </div>
        </div>
    </div>
</template>
<script>
import adddTopic from './addTopic'
export default {
    components: {
        adddTopic
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        experttempinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {
            topiclist_code : {
                title: '',
                detail: '',
                money: '',
                topicArr: [],
                edit:true,
            },
            topichiding:true,
        }
    },

    mounted() {


    },
    created() {

    },

    methods: {
        // 添加话题
        addatopic(){
            this.topiclist_code.edit = true;
            this.experttempinfo.topicList.unshift(this.topiclist_code);
            this.topichiding = false;
        },
        // 删除
        delExpertTopic(id){
            var that = this;
            that.newApi.delExpertTopic({
                id: id
            }).then(res => {
                if (res.isSuccess === 1) {
                     that.utils.sus(res.data);
                     that.$emit('cancel-edit');
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        edittopic(item){
            item.edit = true;
            // var that = this
            // that.newApi.getUserInfo({}).then(res => {
            //     if (res.isSuccess == 1) {
            //         that.uuid = res.data;
            //         if (this.experttempinfo.status == 0 && this.uuid.expertAuthStatus == 0){
            //             item.edit = true;
            //         }else {
            //             this.utils.err('您当前已是专家，不可编辑之前的信息！')
            //         }
            //     }
            // }).catch((err) => {
            //     console.log(err)
            // })
        },
        removethetopic(item){
            item.edit = false;
            this.topichiding = true;
            this.$emit('cancel-edit');
        },
    }
}
</script>
<style scoped>
.info_content {
    width: 1500px;
    font-size: 20px;
    margin: 30px auto;
    overflow: hidden;
}
.namespace {
    font-weight: 600;
    line-height: 40px;
    white-space: pre-wrap;
    width: 1400px;
}
.dashedpace {
    width: 1370px;
}
</style>
