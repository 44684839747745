<template>
    <div style="width: 100%">
        <div style="margin-left: 60px;margin-top: 20px;">
            <div class="hand op sc" v-if="hidethesis" @click="addpaperform" style="display: flex;align-items: center;padding-bottom: 10px;width: 100px">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/add_expert.png" style="width: 20px;height: 20px;">
                <span style="color: #1C7EFF;font-size: 18px;margin-left: 5px;">添加论文</span>
            </div>
        </div>
        <div v-for="(item,index) in experttempinfo.paper" :key="index">
            <div v-if="item.edit == false" class="info_content">
                <div style="display: flex;justify-content: space-between">
                    <div style="">
                        <div class="namespace">论文标题：{{item.title}}</div>
                        <div style="display: flex;justify-content: space-between">
                            <div class="namespace">发表刊物：{{item.publication}}</div>
                            <div class="namespace">刊号：{{item.publicationNo}}</div>
                            <div class="namespace">出版日期：{{item.time}}</div>
                        </div>
                        <div v-if="item.images[0] !== null && item.images[0] !== '' || item.images[1] !== null && item.images[1] !== ''" class="namespace">论文封面（不对外展示）</div>
                        <div style="display: flex">
                            <div v-if="item.images[0] !== null && item.images[0] !== ''" style="width: 500px;height: 300px;margin-left: 30px;margin-bottom: 20px"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="item.images[0]"></div>
                            <div v-if="item.images[1] !== null && item.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;margin-bottom: 20px"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="item.images[1]"></div>
                        </div>
                    </div>
                    <div style="display: flex; margin: 25px 30px;">
                        <div class="hand op sc"  @click="editthesis(item)" style="margin-right: 20px;cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/edit.png" style="width: 34px;height: 34px">
                        </div>
                        <div class="hand op sc" @click="delExpertPaper(index)" style="cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/delete.png" style="width: 34px;height: 34px">
                        </div>
                    </div>
                </div>
            </div>
            <addThesis v-if="item.edit == true" :experttemplist="item" @cancel-edit="closethepaper(item)"></addThesis>
        </div>
    </div>
</template>
<script>
import addThesis from "./addThesis";
export default {
    components: {
        addThesis
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        experttempinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {
            experttemplist:{
                title:'',
                publication:'',
                publicationNo:'',
                time:'',
                images:[],
                edit:true,
                id:''
            },
            hidethesis:true
        }
    },

    mounted() {


    },
    created() {

    },

    methods: {
        //添加论文
        addpaperform(){
            this.experttemplist.edit = true;
            this.experttempinfo.paper.unshift(this.experttemplist);
            this.hidethesis = false;
        },
        // 子组件取消填写
        closethepaper(item){
            item.edit = false;
            this.hidethesis = true;
            this.$emit('cancel-edit');
        },

        // 编辑
        editthesis(item){
            item.edit = true;
            // var that = this
            // that.newApi.getUserInfo({}).then(res => {
            //     if (res.isSuccess == 1) {
            //         that.uuid = res.data;
            //         if (this.experttempinfo.status == 0 && this.uuid.expertAuthStatus == 0){
            //             item.edit = true;
            //         }else {
            //             this.utils.err('您当前已是专家，不可编辑之前的信息！')
            //         }
            //     }
            // }).catch((err) => {
            //     console.log(err)
            // })
        },


        delExpertPaper(index){
            var that = this;
            this.utils.confirm("确定删除论文？", () => {
                that.newApi.delExpertPaper({
                    title:JSON.stringify(that.experttempinfo.paper[index])
                }).then(res => {
                    if (res.isSuccess === 1) {
                        that.experttempinfo.paper.splice(index, 1); // 删除对应索引的数据
                        that.utils.sus(res.data);
                        this.hidethesis = true;
                    }
                }).catch((err) => {
                    console.log(err)
                })
            })
        }

    }
}
</script>
<style scoped>
.info_content {
    width: 1380px;
    min-height: 500px;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, .16);
    border: 1px solid #bdbfc1;
    font-size: 20px;
    margin: 30px auto;
    border-radius: 5px;
}
.namespace {
    font-weight: 600;
    line-height: 40px;
    white-space: pre-wrap;
    color: #999;
    margin: 10px 30px;
}
</style>