<template>
    <div style="width: 100%">
        <!--  论文标题 -->
        <div style="margin-left: 60px;margin-top: 30px;">
            <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">论文标题</div>
            <div style="width: 1230px;height: 54px;margin-top: 5px;">
                <div v-if="experttemplist.id == undefined"   style="font-size: 20px;color: #999;font-weight: 600;line-height: 60px">{{titlecase}}</div>
                <el-input v-else v-model="experttemplist.title" style="width: 1230px;height: 54px;"></el-input>
            </div>
        </div>
        <!-- 发表刊物  -->
        <div style="margin-left: 60px;margin-top: 20px;width: 100%;display: flex">
            <div style="width: 29.5%">
                <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">发表刊物</div>
                <div style="width: 400px;height: 54px;margin-top: 5px;">
                    <el-input v-model="experttemplist.publication" style="width: 400px;height: 54px;"></el-input>
                </div>
            </div>
            <div style="width: 29.5%">
                <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">刊号</div>
                <div style="width: 400px;height: 54px;margin-top: 5px;">
                    <el-input v-model="experttemplist.publicationNo"  style="width: 400px;height: 54px;"></el-input>
                </div>
            </div>
            <div style="width: 26.5%;">
                <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">出版日期</div>
                <div style="width: 350px;height: 54px;margin-top: 5px;">
                    <el-date-picker style="width: 350px;" :picker-options="expireTimeOption" v-model="experttemplist.time" format="yyyy-MM-dd HH:mm:ss"  type="date"  placeholder="选择日期"></el-date-picker>
                </div>
            </div>
        </div>
        <!--  论文封面   -->
        <div style="margin-left: 60px;margin-top: 30px">
            <span style="color: #999999;font-size: 16px;clear: both;">论文封面（不对外展示）</span>
            <div style="display: flex">
                <div style="width: 500px;height: 300px;">
                    <el-upload style="width: 500px;height: 300px;margin-top: 10px;"  :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg_buol(event,0)" >
                        <img v-if="experttemplist.images && experttemplist.images.length > 0 && experttemplist.images[0]" :src="experttemplist.images[0]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                        <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                        <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                            <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                        </div>
                        <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                    </el-upload>
                </div>
                <div style="width: 500px;height: 300px;margin-left: 40px;">
                    <el-upload style="width: 500px;height: 300px;margin-top: 10px;"  :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg_buol(event,1)" >
                        <img v-if="experttemplist.images && experttemplist.images.length > 1 && experttemplist.images[1]" :src="experttemplist.images[1]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                        <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                        <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                            <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                        </div>
                        <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                    </el-upload>
                </div>
                <div style="position: relative">
                    <div style="position: absolute;top: 263px;left:100px;display: flex">
                        <div class="hand op sc" @click="canceThesis(experttemplist)" style="margin-top: 10px;">
                            <el-button type="info" size="medium">取消填写</el-button>
                        </div>
                        <div class="hand op sc" v-if="experttemplist.id == undefined" @click="saveExpertPaper(experttemplist)" style="margin: 10px 40px;">
                            <el-button type="primary" size="success">保存编辑</el-button>
                        </div>
                        <div class="hand op sc" v-else @click="saveExpertPaper(experttemplist)" style="margin: 10px 40px;">
                            <el-button type="primary" size="medium">保存信息</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        experttemplist: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    data() {
        return {
            titleofpaper:'',
            publications:'',
            publicationIssue:'',
            publicationTime:'',
            images_buol:[],
            expireTimeOption: {
                disabledDate(time) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return time.getTime() >= today.getTime();
                }
            },
            titlecase:"",
        }
    },

    mounted() {
        this.titlecase = this.experttemplist.title;
    },
    created() {

    },

    methods: {
        // 设置论文
        saveExpertPaper(experttemplist){
            let that = this;
            var params = {};
            if (this.experttemplist.id == undefined){
                params.title = that.titlecase
            }else {
                params.title = that.experttemplist.title;
            }
            params.publication = that.experttemplist.publication;
            params.publicationNo = that.experttemplist.publicationNo;
            var date = new Date(that.experttemplist.time);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            var h = date.getHours();
            h = h < 10 ? "0" + h : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            var s = date.getSeconds();
            s = s < 10 ? "0" + s : s;

            params.time = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
            params.images = JSON.stringify([that.experttemplist.images[0], that.experttemplist.images[1]])
            that.newApi.saveExpertPaper(params
            ).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus(res.data)
                    that.$emit('cancel-edit',experttemplist);
                    if(that.experttemplist.id != undefined){
                       that.experttemplist.title = "";
                       that.experttemplist.publication = "";
                       that.experttemplist.publicationNo = "";
                       that.experttemplist.time = "";
                       that.experttemplist.images = [];
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        uploadFrontImg_buol(params, e) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 10;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
                        if (!url){
                            return false;
                        }
                        // 添加条件检查以确保images_buol已定义
                        if (!that.experttemplist.images) {
                            that.experttemplist.images = [];
                        }

                        if (that.experttemplist.images.length === 2) {
                            that.experttemplist.images.splice(e,1,url.url)
                        } else {
                            that.experttemplist.images.push(url.url)
                        }
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }

            } else {
                this.$message.error('上传文件大小不能超过 10MB!')
            }
        },
        canceThesis(experttemplist){
            var that = this;
            this.utils.confirm("确定取消论文填写？", () => {
                that.$emit('cancel-edit',experttemplist);
            })
        },
    }
}
</script>