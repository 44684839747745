<template>
    <div style="width: 100%">
            <div style="display: flex;width: 100%">
                <div style="width: 30%;margin-right: 10px;">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">话题名称</div>
                    <div style="width: 400px;height: 54px;margin-top: 5px;">
                        <el-input v-model="topiclist_code.title" style="width: 100%;height: 54px;"></el-input>
                    </div>
                </div>
                <div style="width: 30%;margin-right: 10px;">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">咨询收费标准</div>
                    <div style="width: 400px;height: 54px;margin-top: 5px">
                        <el-input v-model="topiclist_code.money" @input="topiclistMoney($event)" style="width: 100%;height: 54px;"></el-input>
                    </div>
                </div>
                <div style="width: 30%;margin-right: 10px;">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">专业领域</div>
                    <div style="width: 350px;height: 54px;margin-top: 5px">
                        <el-cascader v-model="topiclist_code.topicArr"  placeholder="专家话题领域" :options="typeList" style="width: 100%;height: 54px;"></el-cascader>
                    </div>
                </div>
            </div>
            <span style="color: #999999;font-size: 16px;clear: both;">话题介绍</span>
            <div style="width: 1236px;height: 240px;margin-top: 5px;">
                <el-input type="textarea" :rows="10" v-model="topiclist_code.detail" ></el-input>
            </div>
            <div style="padding:0px 10px 20px 0px">
                <div style="position: relative">
                    <div style="position: absolute;left: 1250px;top:-50px;display: flex">
                        <div class="hand op sc" @click="deleteTopic(topiclist_code)">
                            <el-button type="info" size="small">取消填写</el-button>
                        </div>
                        <div class="hand op sc" v-if="topiclist_code.id == undefined " style="margin-left: 20px;" @click="addExpertTopic(topiclist_code)">
                            <el-button type="primary" size="small" style="background: #1c7eff;border: 1px solid #1c7eff;color: #FFFFFF;">保存信息</el-button>
                        </div>
                        <div class="hand op sc" v-else style="margin-left: 20px;" @click="editExpertTopic(topiclist_code,topiclist_code.id,topiclist_code.title,topiclist_code.money,topiclist_code.detail,topiclist_code.topicId)">
                            <el-button type="success" size="small">保存编辑</el-button>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div  v-if="index !== topiclist_code.length - 1" style="width: 85.5%;border: 1px dashed  #BDBFC1;margin-top: 40px;"></div>-->
        </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        topiclist_code: {
            type: Object ,
            default() {
                return {

                };
            }
        },
    },
    data() {
        return {
            typeList:[]
        }
    },

    mounted() {
       this.getType()
    },
    created() {

    },

    methods: {
        topiclistMoney(){
            this.topiclist_code.money = this.topiclist_code.money.replace(/[^0-9]/g, '');
            this.topiclist_code.money = this.topiclist_code.money.replace(/[^\d]/g, ''); // 仅保留数字
            if (this.topiclist_code.money === '' || parseInt(this.topiclist_code.money) < 1) {
                this.topiclist_code.money = '1';
            }
        },
        // 删除话题
        deleteTopic(item){
            var that = this;
            this.utils.confirm("确定移除？", () => {
                that.$emit('cancel-edit',item);
            })
        },
        // 保存信息专家话题
        addExpertTopic(item){
            let that = this;
            if (this.topiclist_code.title == ''){
                this.utils.err('话题名称不能为空')
                return
            }
            if ( this.topiclist_code.money <= 0){
                this.utils.err('话题金额不能为空或者不能为0')
                return
            }
            if (this.topiclist_code.topicArr == ""){
                this.utils.err('话题专业领域不能为空')
                return
            }
            if (this.topiclist_code.detail == ''){
                this.utils.err('话题内容不能为空')
                return
            }
            item.topicId = item.topicArr[0];
            that.topiclist_code = item;
            let data = JSON.parse(JSON.stringify(item));
            data.topicArr = JSON.stringify( item.topicArr)
            that.newApi.addExpertTopic(data).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus(res.data);
                    that.$emit('cancel-edit',item);
                    that.topiclist_code.title = "";
                    that.topiclist_code.money = "";
                    that.topiclist_code.topicArr = "";
                    that.topiclist_code.detail = ""
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 编辑专家话题
        editExpertTopic(item,id,title,money,detail,topicId){
            let that = this;
            that.newApi.editExpertTopic({
                id:id,
                title:title,
                money:money,
                detail:detail,
                topicId:topicId,
            }).then(res => {
                that.utils.sus(res.data);
                that.$emit('cancel-edit',item);
            }).catch((err) => {
                console.log(err)
            })
        },
        // 获取专业领域
        getType() {
            this.newApi.getTypeList({
                lv: 3
            }).then(res => {
                this.typeList = res.data
            })
        },

    }
}
</script>