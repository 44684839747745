<template>
    <div style="width: 100%">
            <!--  著作名称      -->
            <div style="margin-left: 60px;margin-top: 30px;">
                <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">著作名称</div>
                <div style="width: 1230px;height: 54px;margin-top: 5px;">
                    <div v-if="monographlist.id == undefined" style="font-size: 20px;color: #999;font-weight: 600;line-height: 60px">{{titlecase}}</div>
                    <el-input v-else v-model="monographlist.name" style="width: 1230px;height: 54px;"></el-input>
                </div>
            </div>
            <!-- 出版社 -->
            <div style="margin-left: 60px;margin-top: 20px;width: 100%;display: flex">
                <div style="width: 29.5%">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">出版社</div>
                    <div style="width: 400px;height: 54px;margin-top: 5px;">
                        <el-input v-model="monographlist.form" style="width: 400px;height: 54px;"></el-input>
                    </div>
                </div>
                <div style="width: 29.5%">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">ISBN（国际标准书号）</div>
                    <div style="width: 400px;height: 54px;margin-top: 5px;">
                        <el-input v-model="monographlist.ISBN" style="width: 400px;height: 54px;"></el-input>
                    </div>
                </div>
                <div style="width: 26.5%;">
                    <div style="color: #999999;font-size: 16px;clear: both;margin-top: 16px">出版日期</div>
                    <div style="width: 350px;height: 54px;margin-top: 5px;">
                        <el-date-picker style="width: 350px;" v-model="monographlist.time" :picker-options="expireTimeOption" format="yyyy-MM-dd HH:mm:ss"  type="date"  placeholder="选择日期"></el-date-picker>
                    </div>
                </div>
            </div>
            <!-- 著作封面  -->
            <div style="margin-left: 60px;margin-top: 30px">
                <span style="color: #999999;font-size: 16px;clear: both;">著作封面（不对外展示）</span>
                <div style="display: flex">
                    <div style="width: 500px;height: 300px;">
                        <el-upload  style="width: 500px;height: 300px;margin-top: 10px;" :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg_url(event,0)" >
                            <img v-if="monographlist.images && monographlist.images.length > 0 && monographlist.images[0]":src="monographlist.images[0]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                            <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                            <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                                <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                            </div>
                            <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                        </el-upload>
                    </div>
                    <div style="width: 500px;height: 300px;margin-left: 40px;">
                        <el-upload  style="width: 500px;height: 300px;margin-top: 10px;" :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg_url(event,1)" >
                            <img v-if="monographlist.images && monographlist.images.length > 1 && monographlist.images[1]" :src="monographlist.images[1]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                            <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                            <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                                <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                            </div>
                            <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                        </el-upload>
                    </div>
                    <div style="position: relative">
                        <div style="position: absolute;top: 263px;left:100px;display: flex">
                            <div class="hand op sc" @click="cancel(monographlist)" style="margin-top: 10px;">
                                <el-button type="info" size="medium">取消填写</el-button>
                            </div>
                            <div class="hand op sc" v-if="monographlist.id == undefined" @click="saveExpertMonograph(monographlist)" style="margin: 10px 40px;">
                                <el-button type="primary" size="success">保存编辑</el-button>
                            </div>

                            <div class="hand op sc" v-else @click="saveExpertMonograph(monographlist)"  style="margin: 10px 40px;">
                                <el-button type="primary" size="medium">保存信息</el-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        monographlist: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    data() {
        return {
            workName:"",
            publish:"",
            isbn:"",
            deadline:'',
            images_url:[],
            expireTimeOption: {
                disabledDate(time) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return time.getTime() >= today.getTime();
                }
            },
            titlecase:""
        }
    },

    mounted() {
        this.titlecase = this.monographlist.name;
    },
    created() {

    },

    methods: {
        // 设置专家专著
        saveExpertMonograph(monographlist){
            let that = this;
            var params = {};
            if (this.monographlist.id == undefined){
                params.name = that.titlecase
            }else {
                params.name = that.monographlist.name;
            }
            params.form = that.monographlist.form;
            params.ISBN = that.monographlist.ISBN;
            var date = new Date(that.monographlist.time);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            var h = date.getHours();
            h = h < 10 ? "0" + h : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            var s = date.getSeconds();
            s = s < 10 ? "0" + s : s;

            params.time = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
            params.images = JSON.stringify([that.monographlist.images[0], that.monographlist.images[1]])
            that.newApi.saveExpertMonograph(params).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus(res.data);
                    that.$emit('cancel-edit',monographlist);
                    if (this.monographlist.id != undefined){
                        that.monographlist.name = "";
                        that.monographlist.form = "";
                        that.monographlist.ISBN = "";
                        that.monographlist.images = [];
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        uploadFrontImg_url(params, e) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 10;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
                        if (!url){
                            return false;
                        }
                        // 添加条件检查以确保images_buol已定义
                        if (!that.monographlist.images) {
                            that.monographlist.images = [];
                        }

                        if (that.monographlist.images.length === 2) {
                            that.monographlist.images.splice(e,1,url.url)
                        } else {
                            that.monographlist.images.push(url.url)
                        }
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }

            } else {
                this.$message.error('上传文件大小不能超过 10MB!')
            }
        },
        //  取消填写
        cancel(monographlist){
            var that = this;
            that.utils.confirm("确定取消出版专著填写？", () => {
                that.$emit('cancel-edit',monographlist);
            })
        },

    }
}
</script>