<template>
    <div style="width: 100%;">
            <!-- 头衔名称  -->
            <div style="margin-top: 40px;margin-left: 60px;">
                <span style="color: #999999;font-size: 16px;">社会头衔名称</span>
                <div style="clear: both;margin-top: 10px;">
                    <div v-if="topiclist_code.id == undefined" style="font-size: 20px;color: #999;font-weight: 600">{{titlecase}}</div>
                    <div v-else>
                         <el-input v-model="topiclist_code.title" style="width: 1230px;height: 54px;"></el-input>
                    </div>
                </div>
            </div>
            <!-- 头衔证书 -->
            <div style="margin-left: 60px;margin-top: 10px;">
                <span style="color: #999999;font-size: 16px;">头衔证书（不对外展示）</span>
                <div style="display: flex">
                    <div style="width: 500px;height: 300px;">
                        <el-upload style="width: 500px;height: 300px;margin-top: 10px;"  :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg(event,0)" >
                            <img v-if="topiclist_code.images && topiclist_code.images.length > 0 && topiclist_code.images[0]" :src="topiclist_code.images[0]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                            <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                            <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                                <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                            </div>
                            <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                        </el-upload>
                    </div>
                    <div style="width: 500px;height: 300px;margin-left: 40px;">
                        <el-upload  style="width: 500px;height: 300px;margin-top: 10px;"  :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple  :http-request="(event) =>uploadFrontImg(event,1)" >
                            <img v-if="topiclist_code.images && topiclist_code.images.length > 1 && topiclist_code.images[1]" :src="topiclist_code.images[1]" style="width: 498px;height: 298px;border-radius: 5px;object-fit: cover;">
                            <i class="el-icon-plus icon-plus" style="font-size: 44px;font-weight: 600;color: #2970ff;text-shadow: 0 3px 6px rgba(0, 0, 0, .16);margin-top: 91px"></i>
                            <div style="font-size: 18px;font-weight: bold;line-height: 40px;display: flex;justify-content: center">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/upload.png" style="width: 22px;height: 20px;margin-top: 10px;">
                                <span style="margin-left: 5px;">点击或拖动上传图片（5M以内）</span>
                            </div>
                            <span style="color: #999999;font-size: 16px;">仅限jpg、png、bmp等图片格式</span>
                        </el-upload>
                    </div>
                    <div style="position: relative">
                        <div style="position: absolute;top: 263px;left:100px;display: flex">
                            <div class="hand op sc" @click="deletetitle(topiclist_code)" style="margin-top: 10px;">
                                <el-button type="info" size="medium">取消填写</el-button>
                            </div>
                            <div class="hand op sc" v-if="topiclist_code.id == undefined" @click="editsaveExpertTitle(topiclist_code)" style="margin: 10px 40px;">
                                <el-button type="primary" size="success">保存编辑</el-button>
                            </div>
                            <div class="hand op sc" v-else @click="saveExpertTitle(topiclist_code)" style="margin: 10px 40px;">
                                <el-button type="primary" size="medium">保存信息</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        topiclist_code: {
            type: Object ,
            default() {
                return {

                };
            }
        },
        // type 0 新增 1 编辑
        // type:{
        //     type:Number,
        //     default() {
        //         return {};
        //     }
        // }
    },
    data() {
        return {
            titlecase:''
        }
    },

    mounted() {
        this.titlecase = this.topiclist_code.title;
    },
    watch: {

    },
    created() {

    },

    methods: {
        uploadFrontImg(params, e) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 10;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
                        if (!url){
                            return false;
                        }
                        // 添加条件检查以确保images_buol已定义
                        if (!that.topiclist_code.images) {
                            that.topiclist_code.images = [];
                        }

                        if (that.topiclist_code.images.length === 2) {
                            that.topiclist_code.images.splice(e,1,url.url)
                        } else {
                            that.topiclist_code.images.push(url.url)
                        }
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }

            } else {
                this.$message.error('上传文件大小不能超过 10MB!')
            }
        },
        // 新增
        saveExpertTitle(item){
            let that = this;
            that.newApi.saveExpertTitle({
                title:that.topiclist_code.title,
                images:JSON.stringify([that.topiclist_code.images[0], that.topiclist_code.images[1]]),
            }).then(res => {
                if (res.isSuccess === 1) {
                   that.utils.sus(res.data);
                   that.$emit('cancel-edit',item);
                   that.topiclist_code.title = '';
                   that.topiclist_code.images = []
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 保存编辑
        editsaveExpertTitle(item){
            let that = this;
            that.newApi.saveExpertTitle({
                title:that.titlecase,
                images:JSON.stringify([that.topiclist_code.images[0], that.topiclist_code.images[1]]),
            }).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus(res.data);
                    that.$emit('cancel-edit',item);
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        //  取消填写
        deletetitle(item){
            var that = this;
            this.utils.confirm("确定取消社会头衔填写？", () => {
                that.$emit('cancel-edit',item);
            })
        }
    }
}
</script>