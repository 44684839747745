<template>
    <div style="width: 100%">
        <div style="display: flex; justify-content: center; margin-top: 40px; margin-bottom: 30px;">
            <div style="width: 100px; display: flex; justify-content: center;">
                <div style="width: 80px; height: 80px;">
                    <img style="width: 80px; height: 80px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/pass.png">
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: center;">
            <div @click="editorialexpert" class="hand op sc" style="width: 148px;height: 36px;background: #031F88;border-radius: 30px 30px 30px 30px;display: flex;align-items: center;justify-content: center">
                <div style="color: #FFFFFF;font-size: 18px;font-weight: bold;cursor: pointer">修改专家信息</div>
            </div>
            <div @click="a('/expert/space/'+ 0)" class="hand op sc" style="width: 148px;height: 36px;background: #2970FF;border-radius: 30px 30px 30px 30px;display: flex;align-items: center;justify-content: center;margin-left: 20px">
                <div style="color: #FFFFFF;font-size: 18px;font-weight: bold;cursor: pointer">进入专家空间</div>
            </div>
        </div>
        <div style="margin: 0px auto;width: 100%;height: 100%">
            <waitingForApproval :expertinfo="expertinfo"></waitingForApproval>
        </div>
    </div>
</template>
<script>
import waitingForApproval from "./waitingForApproval"
export default {
    components: {
        waitingForApproval,
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {

        }
    },

    mounted() {

    },

    methods: {
        editorialexpert(){
            // 0 是没有审核 1是待审核
            if(this.expertinfo.status == 0 && this.user.expertAuthStatus == 2){
               this.user.expertAuthStatus = 0
               this.$parent.nextStep()
            }else {
               this.utils.err("当前专家信息正在审核,不可编辑!")
            }
        }
    }
}
</script>