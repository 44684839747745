import { render, staticRenderFns } from "./topicInfo.vue?vue&type=template&id=7838e94a&scoped=true&"
import script from "./topicInfo.vue?vue&type=script&lang=js&"
export * from "./topicInfo.vue?vue&type=script&lang=js&"
import style0 from "./topicInfo.vue?vue&type=style&index=0&id=7838e94a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7838e94a",
  null
  
)

export default component.exports