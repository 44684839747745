<template>
    <div style="width: 100%;">
        <!--   社会头衔   -->
         <div class="nameclass">获取社会头衔</div>
         <socialTitlesInfo :experttempinfo="experttempinfo" @cancel-edit="cancel"></socialTitlesInfo>
         <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 40px;"></div>
          <!-- 专家自述   -->
         <div style="margin-left: 60px;margin-top: 30px;">
             <span style="font-size: 18px;color: #444444;font-weight: 600"><span style="color: red">*</span>专家自述（主要介绍自己专业业绩和履历）</span>
             <div v-if="editing" style="display: flex;justify-content: space-between">
                 <div style="width: 1230px;min-height: 30px;margin-top: 12px;">
                     <el-input
                         type="textarea"
                         :autosize="{ minRows: 2, maxRows: 6}"
                         placeholder="请输入专家自述"
                         v-model="describe"
                         maxlength="2000"
                         show-word-limit>
                     </el-input>
                 </div>
                 <div class="hand op sc" @click="saveExpertContent" style="width: 108px;height: 40px;margin-right: 60px;margin-top: 20px;">
                     <el-button type="primary" style="background: #409EFF;border: 1px solid #409EFF;color: #FFFFFF">保存信息</el-button>
                 </div>
             </div>
             <div v-else class="info_content">
                 <div style="display: flex;justify-content: space-between">
                     <div class="namespace">{{describe}}</div>
                     <div style="display: flex; margin: 25px 30px;">
                         <div class="hand op sc" @click="editreadme" style="margin-right: 20px;cursor: pointer">
                             <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/edit.png" style="width: 34px;height: 34px">
                         </div>
                     </div>
                 </div>
             </div>
         </div>

         <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 80px;"></div>
         <div class="nameclass">获取出版专著</div>
        <monographInfo :experttempinfo="experttempinfo" @cancel-edit="imgood"></monographInfo>

        <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 40px;"></div>
        <div class="nameclass">获取发表论文</div>

        <thsisInfo :experttempinfo="experttempinfo" @cancel-edit="getridof"></thsisInfo>

        <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 40px;"></div>
        <!--  是否参与制定本专业法律法规 -->
        <div style="margin-left: 60px;margin-top: 30px;">
            <span style="font-size: 18px;color: #444444;font-weight: 600">是否有参与制定本专业法律法规、标准规范情况与其他佐证资料（例如：媒体报道、有影响力的会议等）</span>
            <div v-if="editothercases" style="display: flex;justify-content: space-between">
                <div  style="width: 1230px;min-height: 30px;margin-top: 12px;">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 6}"
                        placeholder="请输入是否有参与制定本专业法律法规、标准规范情况"
                        v-model="othercases"
                        maxlength="200"
                        show-word-limit>
                    </el-input>
                </div>
                <div class="hand op sc" @click="saveExpertOther" style="width: 108px;height: 40px;margin-right: 60px;margin-top: 20px;">
                    <el-button type="primary" style="background: #409EFF;border: 1px solid #409EFF;color: #FFFFFF">保存信息</el-button>
                </div>
            </div>
            <div v-else class="info_content">
                <div style="display: flex;justify-content: space-between">
                    <div class="namespace">{{othercases}}</div>
                    <div style="display: flex; margin: 25px 30px;">
                        <div class="hand op sc" @click="editcases" style="margin-right: 20px;cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/edit.png" style="width: 34px;height: 34px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 80px;"></div>
        <!--  专家话题  -->
        <div style="margin-left: 60px;margin-top: 30px;">
            <span style="font-size: 20px;color: #444444;font-weight: 600"><span style="color: red">*</span>专家话题</span>
            <topicInfo :experttempinfo="experttempinfo" @cancel-edit="removethetopic"></topicInfo>
        </div>

        <div style="width: 92%;border: 1px dashed  #BDBFC1;margin-left:60px;margin-top: 50px;"></div>
        <div style="display: flex;justify-content: center;padding-bottom: 30px;margin-top: 30px">
            <div @click="submitapplication()" class="hand op sc" style="width: 136px;height: 40px;">
                <el-button type="primary" style="font-size: 18px;">提交申请</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import socialTitlesInfo from "./socialTitle/socialTitlesInfo";
import monographInfo from './publishMonographs/monographInfo';
import thsisInfo from './publishaPaper/thsisInfo';
import addTopic from "./topic/addTopic";
import topicInfo from "./topic/topicInfo"
export default {
    components: {socialTitlesInfo,monographInfo,thsisInfo,addTopic,topicInfo},
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {
            describe:'',
            othercases:'',
            experttempinfo:{},
            editing: true,
            editothercases:true,

        }
    },

    mounted() {
        // 新增                                                              编辑
        if((this.expertinfo.status == 0 && this.user.expertAuthStatus === 0 ) || (this.expertinfo.status == 0 && this.user.expertAuthStatus === 2 )){
            this.getExpertTempInfo();
        }

    },
    created() {

    },

    methods: {
        cancel(){
            this.getExpertTempInfo()
        },
        imgood(){
            this.getExpertTempInfo()
        },

        getridof(){
            this.getExpertTempInfo()
        },
        // 提交审核
        submitapplication(){
            var that = this;
            if (that.describe == ""){
                that.utils.err("专家自述不能为空！")
                return
            }
            that.newApi.submitExpert({}).then(res => {
                if(res.isSuccess == 1){
                    that.utils.sus(res.data);
                    that.$emit('cancel-edit');
                }

            }).catch((err) => {
                console.log(err)
            })
        },
        //  保存专家自述
        saveExpertContent(){
            let that = this;
            that.newApi.saveExpertContent({
                content:that.describe,
            }).then(res => {
                if(res.isSuccess == 1) {
                    that.utils.sus(res.data);
                    that.editing = false
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 保存是否有参与制定本专业法律法规、标准规范情况与其他佐证资料
        saveExpertOther(){
            let that = this;
            that.newApi.saveExpertOther({
                other:that.othercases,
            }).then(res => {
                if (res.isSuccess == 1){
                    that.utils.sus(res.data);
                    that.editothercases = false
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        removethetopic(){
            this.getExpertTempInfo()
        },
        // 获取专家信息
        getExpertTempInfo(){
            let that = this;
            that.newApi.getExpertTempInfo({}).then(res => {
                for (let i = 0;i<res.data.topicList.length;i++){
                    res.data.topicList[i]['edit']=false
                }

                for (let i = 0;i<res.data.titleInfo.length;i++){
                    res.data.titleInfo[i]['edit']=false
                }

                for (let i = 0;i<res.data.monograph.length;i++){
                    res.data.monograph[i]['edit']=false
                }

                // 循环论文
                for (let i = 0;i<res.data.paper.length;i++){
                    res.data.paper[i]['edit']=false
                }

                this.experttempinfo = res.data;
                // 专家自述
                that.describe = res.data.content;
                // 是否有参与制定本专业法律法规、标准规范情况与其他佐证资料（例如：媒体报道、有影响力的会议等）
                that.othercases = res.data.other;

                if (this.describe === '') {
                    this.editing = true;
                } else {
                    this.editing = false;
                }
                if(this.othercases === ''){
                    this.editothercases = true;
                } else {
                    this.editothercases = false;
                }

            }).catch((err) => {
                console.log(err)
            })
        },

        editreadme(){
          this.editing = true
        },
        editcases(){
            this.editothercases = true
        }
    }
}
</script>
<style scoped>
/deep/ .el-upload-dragger {
    width: 500px!important;
    height: 300px!important;
}
/deep/.el-textarea__inner {
    resize: none;
}
.upload-demo {
    vertical-align: middle;
}

.nameclass {
    font-size: 20px;
    color: #444444;
    font-weight: 600;
    margin-top: 20px;
    margin-left: 60px;
}

.info_content {
    width: 1380px;
    min-height: 85px;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, .16);
    border: 1px solid #bdbfc1;
    font-size: 20px;
    margin: 30px 0px;
    border-radius: 5px;
}
.namespace {
    font-weight: 600;
    line-height: 40px;
    color: #999;
    white-space: pre-wrap;
    margin: 20px 30px;
}
</style>
