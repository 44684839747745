<template>
    <div style="width: 100%;height: 100%">
        <div style="width: 100%;background: #FFFFFF;">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/baigongbao_expert.png" style="height: 49px;width: auto;margin: 0 10px;"/>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
<!--                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/notice.png" style="height: 22px;width: auto"/>-->

<!--                    <img :src="user.headIco" style="height: 30px;width: 30px;margin: 0 10px 0 40px;border-radius: 50%;"/>-->

<!--                    <span style="margin-right: 10px;font-size: 18px;">{{user.nickName}}</span>-->

<!--                    <i class="el-icon-caret-bottom"></i>-->

                </div>
            </div>
        </div>
        <div style="width: 1500px; background: #FFFFFF; min-height: 2600px; margin: 10px auto">
            <div style="width: 100%; height: 164px; background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/background_expert.png'); background-size: cover; background-repeat: no-repeat; background-position: center;">
                <!--   时间线  -->
                <div style="display: flex; justify-content: center; align-items: center; padding-top: 40px;margin-left: 130px">
                    <div v-for="(item, index) in steps" :key="index" style="display: flex; align-items: center; width: 20%;">
                        <div style="width: 50%;">
                            <div :class="{code: index  <= rateOfProgress}"  class="bold">
                                <span :class="{spanp: index  <= rateOfProgress}" class="primary">{{item.number}}</span>
                            </div>
                            <div  :class="{error: index  <= rateOfProgress}" class="dark">{{item.label}}</div>
                        </div>
                        <div v-if="index !== 2" style="width: 50%; display: flex; justify-content: center;">
                            <div style="width: 44px; height: 20px;">
                                <img  v-if="index + 1 <= rateOfProgress" style="width: 100%; height: 100%;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Progress%20completed.png">
                                <img v-else style="width: 44px;height: 20px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/The%20progress%20is%20not%20completed.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 填写信息 -->
            <div v-if="(expertinfo.status === 0 && user.expertAuthStatus === 0)" style="width: 100%;height: 100%">
                <information :expertinfo="expertinfo"  @cancel-edit="submitforreview"></information>
            </div>
            <!--  等待审核 -->
            <div v-if="expertinfo.status === 1 && user.expertAuthStatus === 0" style="width: 100%;height: 100%">
                 <waitingForApproval :expertinfo="expertinfo"></waitingForApproval>
            </div>
            <!--  认证通过 -->
            <div v-if="expertinfo.status === 0 && user.expertAuthStatus === 2" style="width: 100%;height: 100%">
                 <certificationPassed :expertinfo="expertinfo"></certificationPassed>
            </div>

<!--      rateOfProgress状态是0是未提交和可以编辑 1是待审核状态(审核状态不能编辑)   user.expertAuthStatus 0 是未通过  2是已经是专家了   -->
<!--     填写信息 rateOfProgress === 0 和 user.expertAuthStatus === 0 是未审核状态 -->
<!--     编辑信息 rateOfProgress === 0 和 user.expertAuthStatus === 2 这是已经是专家了需要编辑 但是它们公用一个组件     -->
        </div>
    </div>
</template>
<script>
import information from "./componentsBecome/information"
import waitingForApproval from "./componentsBecome/waitingForApproval"
import certificationPassed from "./componentsBecome/certificationPassed"
import AvatarMenu from "../../components/common/AvatarMenu";

export default {
    components: {
        information,
        waitingForApproval,
        certificationPassed,
        AvatarMenu
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data() {
        return {
            steps: [
                {
                    number: 1,
                    label: '填写信息',

                }, {
                    number: 2,
                    label: '等待审核',

                }, {
                    number: 3,
                    label: '认证通过',
                }
            ],
            rateOfProgress:0,

            expertinfo:{},
        }
    },

    mounted() {
      this.getExpertTempInfo();
      // this.rateOfProgress = this.user.expertAuthStatus;
        if(this.expertinfo.status == 0 && this.user.expertAuthStatus == 2){
            this.getUserInfo()
        }


    },
    created() {
        // setTimeout(() => {
        //     if(this.user.authenticationStatus !== 2){
        //         this.$notify({
        //             title: '警告',
        //             message: '请先通过专业认证！',
        //             type: 'warning'
        //         });
        //         window.history.back();
        //     }
        // }, 0);
    },

    methods: {
        getExpertTempInfo(){
            let that = this;
            that.newApi.getExpertTempInfo({}).then(res => {
                if (res.isSuccess == 1) {
                    that.expertinfo = res.data;
                    // 赋值专家审核状态
                    if (that.expertinfo.status == 0 && that.user.expertAuthStatus == 0){
                        this.rateOfProgress = 0;
                    }else if(that.expertinfo.status == 1 && that.user.expertAuthStatus == 0){
                        this.rateOfProgress = 1;
                    }else if(that.expertinfo.status == 0 && that.user.expertAuthStatus == 2){
                        this.rateOfProgress = 2;
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        nextStep(){
            this.rateOfProgress = 0
        },
        submitforreview(){
            this.getExpertTempInfo()
        },
        getUserInfo(){
            var that = this
            that.newApi.getUserInfo({}).then(res => {
                if (res.isSuccess == 1) {
                    that.uuid = res.data;
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>
<style scoped>
.bold {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto
}
.code {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #2970FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto
}
.bold .spanp {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
}
.dark {
    color: #999999;
    font-size: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}
.error {
    color: #2970FF;
    font-size: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}
.primary {
    color: #999999;
    font-size: 20px;
    font-weight: bold;
}

</style>